<template>
  <footer class="bg-coolGray-800 text-coolGray-100 text-sm text-center mt-10 py-16 md:py-24">
    <div class="flex items-center justify-center space-x-4">
      <a
        href="https://twitter.com/GabieNadeauArt"
        title="Gabrielle Nadeau on Twitter"
        target="_blank"
        rel="noreferrer noopener"
        ><SVGTwitter class="h-8 w-8"
      /></a>
      <a
        href="https://www.instagram.com/gabie_nadeau/"
        title="Gabrielle Nadeau on Instagram"
        target="_blank"
        rel="noreferrer noopener"
        ><SVGInstagram class="h-8 w-8"
      /></a>
    </div>
    <p class="mx-8 mt-4">Copyright Gabrielle Nadeau {{ year }}. All rights reserved.</p>
  </footer>
</template>

<script>
import SVGTwitter from '@/components/svg/SVGTwitter'
import SVGInstagram from '@/components/svg/SVGInstagram'
export default {
  components: {
    SVGTwitter,
    SVGInstagram,
  },
  computed: {
    year: function() {
      return new Date().getFullYear()
    },
  },
}
</script>
