<template>
  <!-- HERO SECTION -->
  <transition name="fade">
    <ScrollArrow class="scroll-arrow" v-if="showScrollArrow" />
  </transition>
  <div class="bg-white">
    <div class="flex flex-col sm:flex-row items-center">
      <div class="w-auto sm:w-1/2 p-8">
        <p class="text-3xl md:text-4xl lg:text-6xl font-black text-coolGray-800">
          Hello, welcome to my world of <span class="text-cyan-600">little monsters!</span>
        </p>
        <img
          src="../assets/images/illustrations/GabrielleNadeauArt-Purple-Monster-With-Bunny-Web-1280.jpg"
          alt="Happy Cartoon Monster"
          class="sm:hidden w-2/3 mx-auto"
        />
        <p class="mt-8 text-base md:text-lg lg:text-2xl text-coolGray-500">
          My name is Gabrielle Nadeau, I am a Canadian author, illustrator and visual artist. Here you can
          browse my art, know a bit more about me or contact me. Roar!
        </p>
        <div>
          <BaseButton @click.prevent="handleContactBtn">
            Get in touch
          </BaseButton>
        </div>
      </div>
      <div class="w-1/2 hidden sm:block">
        <img
          src="../assets/images/illustrations/GabrielleNadeauArt-Purple-Monster-With-Bunny-Web-1280.jpg"
          alt="Happy Cartoon Monster"
        />
      </div>
    </div>
  </div>

  <!-- BOOKS GALLERY -->
  <div class="mt-12 sm:mt-20">
    <h2 class="font-black text-3xl uppercase border-coolGray-800 border-b-2">Book Covers</h2>
    <ThreeColumnGallery :col1="booksCol1" :col2="booksCol2" :col3="booksCol3" />
  </div>

  <!-- ILLUSTRATIONS GALLERY -->
  <div class="mt-12 sm:mt-20">
    <h2 class="font-black text-3xl uppercase border-coolGray-800 border-b-2">Illustrations</h2>
    <ThreeColumnGallery :col1="illustrationsCol1" :col2="illustrationsCol2" :col3="illustrationsCol3" />
  </div>

  <!-- PAINTINGS GALLERY -->
  <div class="mt-12 sm:mt-20">
    <h2 class="font-black text-3xl uppercase border-coolGray-800 border-b-2">Paintings</h2>
    <ThreeColumnGallery :col1="paintingsCol1" :col2="paintingsCol2" :col3="paintingsCol3" />
  </div>
</template>

<script>
// @ is an alias to /src
import ThreeColumnGallery from '@/components/ThreeColumnGallery'
import BaseButton from '@/components/BaseButton'
import ScrollArrow from '@/components/ScrollArrow'

export default {
  name: 'Home',
  components: {
    ThreeColumnGallery,
    BaseButton,
    ScrollArrow,
  },
  created() {
    this._scrollListener()
  },
  mounted() {},
  data: function() {
    return {
      showScrollArrow: true,
      booksCol1: [
        {
          path: 'images/book_covers/MonstersGuidetoAnger-Cover-640.png',
          alt: "A Monster's Guide To Anger",
          link: 'images/book_covers/MonstersGuidetoAnger-Cover-1280.png',
        },
      ],
      booksCol2: [
        {
          path: 'images/book_covers/MonstersGuidetoJoy-Cover-640.png',
          alt: "A Monster's Guide To Joy",
          link: 'images/book_covers/MonstersGuidetoJoy-Cover-1280.png',
        },
      ],
      booksCol3: [
        {
          path: 'images/book_covers/MonstersGuidetoShyness-Cover-640.png',
          alt: "A Monster's Guide To Shyness",
          link: 'images/book_covers/MonstersGuidetoShyness-Cover-1280.png',
        },
      ],
      illustrationsCol1: [
        {
          path: 'images/illustrations/GabrielleNadeauArt-Monster-Little-Devil-Web-640.jpg',
          alt: 'Devilish cartoon monster',
          link: 'images/illustrations/GabrielleNadeauArt-Monster-Little-Devil-Web-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-PranceAroundTown-640.jpg',
          alt: 'Monster banging on a pot and spreading joy.',
          link: 'images/illustrations/GabrielleNadeauArt-PranceAroundTown-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Blue-Monster-Angry-Web-640.jpg',
          alt: 'Angry cartoon monster',
          link: 'images/illustrations/GabrielleNadeauArt-Blue-Monster-Angry-Web-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Monster-Under-table-640.jpg',
          alt: 'Blue monster laying under a table with his teddy.',
          link: 'images/illustrations/GabrielleNadeauArt-Monster-Under-table-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Blue-Monster-Roll-On-The_Ground-Web-640.jpg',
          alt: 'Cartoon monster throwing a fit',
          link: 'images/illustrations/GabrielleNadeauArt-Blue-Monster-Roll-On-The_Ground-Web-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Monster-Dancer-Web-640.jpg',
          alt: 'Illustrated ballerina monster',
          link: 'images/illustrations/GabrielleNadeauArt-Monster-Dancer-Web-1280.jpg',
        },
      ],
      illustrationsCol2: [
        {
          path: 'images/illustrations/GabrielleNadeauArt-MouseInCanyon-640.jpg',
          alt: 'Mouse on an adventure takes a moment to rest in a canyon.',
          link: 'images/illustrations/GabrielleNadeauArt-MouseInCanyon-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-PattAndLilySymbol-640.jpg',
          alt: 'Two little monsters waving.',
          link: 'images/illustrations/GabrielleNadeauArt-PattAndLilySymbol-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Emma-Skating-640.jpg',
          alt: 'Toddler trying to skate.',
          link: 'images/illustrations/GabrielleNadeauArt-Emma-Skating-1280.jpg',
        },

        {
          path: 'images/illustrations/GabrielleNadeauArt-Monster-Green-Guy-Web-640.jpg',
          alt: 'Illustrated monster looking out',
          link: 'images/illustrations/GabrielleNadeauArt-Monster-Green-Guy-Web-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Blue-Monster-Teddy-Bear-Web-640.png',
          alt: 'Illustrated monster giving teddy bear a hug',
          link: 'images/illustrations/GabrielleNadeauArt-Blue-Monster-Teddy-Bear-Web-1280.png',
        },
      ],
      illustrationsCol3: [
        {
          path: 'images/illustrations/GabrielleNadeauArt-Blue-Monster-Pillow-Web-640.jpg',
          alt: 'Illustration of angry monster squishing a pillow',
          link: 'images/illustrations/GabrielleNadeauArt-Blue-Monster-Pillow-Web-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Lily-laughing-640.png',
          alt: 'Lily the monster rolling and laughing',
          link: 'images/illustrations/GabrielleNadeauArt-Lily-laughing-1280.png',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Put-on-a-Big-Show-640.jpg',
          alt: 'Cartoon monster putting on a show for his friends.',
          link: 'images/illustrations/GabrielleNadeauArt-Put-on-a-Big-Show-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Blue-Purple-Monsters-Joyful-Scene-640.jpg',
          alt: 'Two cute cartoon monsters playing with blocks',
          link: 'images/illustrations/GabrielleNadeauArt-Blue-Purple-Monsters-Joyful-Scene-1280.jpg',
        },
        {
          path: 'images/illustrations/GabrielleNadeauArt-Monster-FurryBall-Web-640.jpg',
          alt: 'Furry illustrated monster',
          link: 'images/illustrations/GabrielleNadeauArt-Monster-FurryBall-Web-1280.jpg',
        },
      ],
      paintingsCol1: [
        {
          path: 'images/paintings/GabrielleNadeauArt-Francis-Hanging-Salmon-Web-640.jpg',
          alt: 'Hanging Salmon - 14" x 18" oil on canvas',
          link: 'images/paintings/GabrielleNadeauArt-Francis-Hanging-Salmon-Web-1280.jpg',
        },
        {
          path: 'images/paintings/GabrielleNadeauArt-GabrielleLabrie-Web-640.jpg',
          alt: 'Gabrielle Labrie - 18" x 12" oil on wood panel',
          link: 'images/paintings/GabrielleNadeauArt-GabrielleLabrie-Web-1280.jpg',
        },
        {
          path: 'images/paintings/GabrielleNadeauArt-Bi-wekly-ritual-Web-640.jpg',
          alt: 'A Bi-weekly Ritual - 20" x 24" oil on canvas',
          link: 'images/paintings/GabrielleNadeauArt-Bi-wekly-ritual-Web-1280.jpg',
        },
      ],
      paintingsCol2: [
        {
          path: 'images/paintings/GabrielleNadeauArt-CigaretteBreakOnTheWater-Web-640.jpg',
          alt: 'Cigarette Break - 40" x 20" oil on canvas',
          link: 'images/paintings/GabrielleNadeauArt-CigaretteBreakOnTheWater-Web-1280.jpg',
        },
        {
          path: 'images/paintings/GabrielleNadeauArt-Maasai-Web-1280.jpg',
          alt: 'Maasai - 16" x 40" oil on canvas',
          link: 'images/paintings/GabrielleNadeauArt-Maasai-Web-1280.jpg',
        },
        {
          path: 'images/paintings/GabrielleNadeauArt-Chuck-Taylors-Web-640.jpg',
          alt: 'Chuck Taylor(s) - 68" x 28" oil on canvas',
          link: 'images/paintings/GabrielleNadeauArt-Chuck-Taylors-Web-1280.jpg',
        },
      ],
      paintingsCol3: [
        {
          path: 'images/paintings/GabrielleNadeauArt-Bushman-Web-640.jpg',
          alt: 'Bushman - 24" x 24" oil on canvas',
          link: 'images/paintings/GabrielleNadeauArt-Bushman-Web-1280.jpg',
        },
        {
          path: 'images/paintings/GabrielleNadeauArt-MorningCanoe-Web-640.jpg',
          alt: 'Canoeing at Dawn - oil on canvas',
          link: 'images/paintings/GabrielleNadeauArt-MorningCanoe-Web-1280.jpg',
        },
        {
          path: 'images/paintings/GabrielleNadeauArt-McKay-Web-640.jpg',
          alt: 'Scott Hiking With Grandson - oil on canvas',
          link: 'images/paintings/GabrielleNadeauArt-McKay-Web-1280.jpg',
        },
        {
          path: 'images/paintings/GabrielleNadeauArt-Sam_Picking_his_vitamins-Web-640.jpg',
          alt: 'Sam Picking His Vitamins - 10" x 12" oil on canvas',
          link: 'images/paintings/GabrielleNadeauArt-Sam_Picking_his_vitamins-Web-1280.jpg',
        },
      ],
    }
  },
  methods: {
    handleContactBtn: function() {
      this.$router.push({ name: 'Contact' })
    },
    _scrollListener: function() {
      document.addEventListener('scroll', this.handleScroll)
    },
    handleScroll: function() {
      if (window.scrollY > 200) {
        // remove the scroll arrow
        this.showScrollArrow = false
        // remove event listener
        document.removeEventListener('scroll', this.handleScroll)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.scroll-arrow {
  position: fixed;
  bottom: 80px;
  left: 50%;
  transform: translateX(-50%);
}

.fade-leave-from {
  opacity: 1;
}

.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-leave-to {
  opacity: 0;
}
</style>
