<template>
  <div class="box">
    <span></span>
    <span></span>
    <span></span>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.box {
  span {
    display: block;
    width: 20px;
    height: 20px;
    border-bottom: 1px solid black;
    border-right: 1px solid black;
    transform: rotate(45deg);
    margin: -10px;
    animation: animate 2s infinite;

    &:nth-child(2) {
      animation-delay: 0.2s;
    }

    &:nth-child(1) {
      animation-delay: 0.4s;
    }
  }
}

@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-20px, -20px);
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: rotate(45deg) translate(20px, 20px);
  }
}
</style>
