<template>
  <div class="container mx-auto">
    <div class="mx-2 sm:mx-4">
      <Header />
      <router-view />
    </div>
  </div>
  <Footer />
</template>

<script>
import Header from './components/Header'
import Footer from './components/Footer'
export default {
  components: {
    Header,
    Footer,
  },
}
</script>
