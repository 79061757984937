<template>
  <div class="flex">
    <!-- Column 1 -->
    <div class="w-1/3 mr-4">
      <div class="my-4" v-for="image in column1" :key="image.path" @click="showLightbox(image.index)">
        <img :src="require('@/assets/' + image.path)" :alt="image.alt" class="gallery-image" />
      </div>
    </div>
    <!-- Column 2 -->
    <div class="w-1/3 mr-4">
      <div class="my-4" v-for="image in column2" :key="image.path" @click="showLightbox(image.index)">
        <img :src="require('@/assets/' + image.path)" :alt="image.alt" class="gallery-image" />
      </div>
    </div>
    <!-- Column 3 -->
    <div class="w-1/3">
      <div class="my-4" v-for="image in column3" :key="image.path" @click="showLightbox(image.index)">
        <img :src="require('@/assets/' + image.path)" :alt="image.alt" class="gallery-image" />
      </div>
    </div>
  </div>
  <VueEasyLightbox moveDisabled :visible="lightboxActive" :imgs="imgs" :index="index" @hide="handleHide" />
</template>

<script>
import VueEasyLightbox from 'vue-easy-lightbox'

export default {
  components: {
    VueEasyLightbox,
  },
  props: {
    col1: {
      type: Array,
    },
    col2: {
      type: Array,
    },
    col3: {
      type: Array,
    },
  },
  data: function() {
    return {
      imgs: [],
      column1: [],
      column2: [],
      column3: [],
      index: 0,
      lightboxActive: false,
    }
  },
  created() {
    // loop through col1, col2, col3. Add images to the array.
    const imgs = []
    this.col1.forEach((img, i) => {
      imgs.push({ src: require('@/assets/' + img.link), title: img.alt })
      // copy the items into the data so it can be mutated and add an index property
      const itemCopy = { ...this.col1[i] }
      itemCopy.index = imgs.length - 1
      this.column1.push(itemCopy)
    })
    this.col2.forEach((img, i) => {
      imgs.push({ src: require('@/assets/' + img.link), title: img.alt })
      // copy the items into the data so it can be mutated and add an index property
      const itemCopy = { ...this.col2[i] }
      itemCopy.index = imgs.length - 1
      this.column2.push(itemCopy)
    })
    this.col3.forEach((img, i) => {
      imgs.push({ src: require('@/assets/' + img.link), title: img.alt })
      // copy the items into the data so it can be mutated and add an index property
      const itemCopy = { ...this.col3[i] }
      itemCopy.index = imgs.length - 1
      this.column3.push(itemCopy)
    })

    this.imgs = imgs
  },
  methods: {
    showLightbox(i) {
      this.index = i
      this.lightboxActive = true
    },
    handleHide() {
      this.lightboxActive = false
    },
  },
}
</script>

<style lang="scss" scoped>
.gallery-image {
  @apply shadow-md rounded-lg w-full h-full cursor-pointer;

  transition: transform 0.3s ease-in-out;

  &:hover {
    transform: scale(1.02);
  }
}
</style>

<style lang="scss">
.vel-img-title {
  @apply text-xl;
}
</style>
