<template>
  <div class="py-8">
    <div class="flex flex-col md:flex-row items-end">
      <header class="flex flex-col items-center mr-auto">
        <h1 class="font-gnadeau text-4xl sm:text-5xl">Gabrielle Nadeau</h1>
        <h2 class="text-cyan-700 hidden md:block">Author, Illustrator, and Visual Artist</h2>
      </header>
      <nav class="flex flex-row">
        <router-link to="/" class="link">Home</router-link> |
        <router-link to="/about" class="link">About</router-link> |
        <router-link to="/contact" class="link">Contact</router-link>
      </nav>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.link {
  @apply mx-4 text-coolGray-800 font-bold font-sans;
}

a {
  display: block;
  text-transform: uppercase;

  &.router-link-exact-active {
    @apply text-cyan-700;
  }
}
</style>
